/*eslint-disable*/
import { AdalConfig } from 'react-adal';
import { MatomoSettings } from './shared/MatomoSettings';

interface IConfig {
    azureMapKey: string;
    powerBiUrl: string,
    adalConfig: AdalConfig,
    minimumLoadCapacity: number,
    UseSPAAndApiSeparately: boolean,
    cartoUrl: string,
    cartoToken: string,
    vectuelMapUrl: string,
    mapprUrl: string,
    vectuelProjectName: string,
    chantier360Url: string,
    carbonCounterUrl: string,
    mapBoxToken: string,
    dataProxyApiUrl: string,
    inexApiUrl: string,
    configMapBoxLayersUrl: string,
    matomo: MatomoSettings,
    parisGamesUrl: string,
}

export class ConfigService {
    private static _config: IConfig = null;

    private constructor() {

    }

    static get = () => {
        if (ConfigService._config)
            return ConfigService._config;
        throw console.error('get conf erreur: vous devez réinitialisé votre configuration');
    }

    public static setup = (res) => {
        if (res) {
            var config = JSON.parse(res);
            config.adalConfig.redirectUri = window.location.protocol + "//" + window.location.host;
            ConfigService._config = config;
        }
    }
}