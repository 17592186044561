import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { runWithAdal } from 'react-adal';
import { authContext } from './Auth';
import { ConfigService } from './configService';
import buildinfo from './config/buildinfo.json';
import i18n from "i18next";
import { strings } from './strings';


const App = React.lazy(() => import('./App'));
import './i18n';
import {setRequestLogger} from "./RequestLogger";

window.document.title += ` v3.${buildinfo.versionMajorMinor}.${buildinfo.build.buildNumber}`;
const userLanguage = sessionStorage.getItem('user-language-selection');




function renderApp() {
  const lng = i18n.language;
  userLanguage && userLanguage.length && i18n.changeLanguage(userLanguage) || '';
  strings.setLanguage(userLanguage && userLanguage.length ? userLanguage : lng);
  const baseUrl = "/";
  const rootElement = document.getElementById('root');

  runWithAdal(authContext(), () => {
    ReactDOM.render(
      <BrowserRouter basename={baseUrl}>
        <React.Suspense fallback={<div></div>}>
          <App />
        </React.Suspense>
      </BrowserRouter>,
      rootElement);
    registerServiceWorker();
  }, false);
}

var settingsPromise = new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", 'config/appsettings.json');
  xhr.onload = () => resolve(xhr.responseText);
  xhr.onerror = () => reject(xhr.statusText);
  xhr.send();
});
settingsPromise.then((res) => {
  ConfigService.setup(res);
  setRequestLogger();
  renderApp();
});